import "./banner/Banner.css";
import Banner from "./banner/Banner";
import BookApointment from "./BookApointment/BookApointment";
import Services from "./Services/Services";
import InnerUniverse from "./InnerUniverse/InnerUniverse";
import ClientSays from "./ClientSays/ClientSays";
import Specialist from "./Specialist/Specialist";
import { useEffect } from "react";
import Counter from "../CommonComponents/Counter/Counter";

function Home() {
  // useEffect(() => {
  //     window.scrollTo(0, 0); // Scroll to the top when component mounts
  //   }, []);
  return (
    <>
      <Banner />
      <Specialist />
      <Services />
      <BookApointment />
      <InnerUniverse />
      <Counter />
      <ClientSays />
    </>
  );
}

export default Home;
